<template>
  <section>
    <div id="pdf-content" v-if="dataPenugasan.length > 0">
      <section id="surat">
        <!-- <div class="row mb-2">
          <div class="col-11 offset-1">
            <div class="row" id="kop">
              <div class="col-2 bb">
                <img src="@/assets/logo/logo_surat.png" alt="" />
              </div>
              <div class="col-10 bb">
                <h3 id="judul">
                  PEMERINTAH KABUPATEN BANDUNG <br />
                  DINAS PEKERJAAN UMUM DAN TATA RUANG <br />
                  UPTD PENERANGAN JALAN UMUM <br />
                </h3>
                <p id="alamat">
                  Jl. RAYA SOREANG-BANJARAN KM. 3 SOREANG TELP. 5892773-5892580 –
                  SOREANG
                </p>
              </div>
            </div>
          </div>
        </div> -->
        <div class="row" id="halaman">
          <div class="col">
            <p
              style="
                font-family: 'Times New Roman', Times, serif;
                text-align: center;
                font-size: 15px;
                font-weight: bold;
                overflow: hidden;
                text-decoration: underline;
              "
            >
            KEGIATAN PEMELIHARAAN PJU TAHUN {{ $moment(dataPenugasan.assignment_date).format("YYYY") }}
            </p>

            <div id="content">
              <br />
              <template style="font-size: 8pt;">
                <div>
                  <div>
                    <table style="width: 100%;">
                      <thead>
                        <tr class="text-center">
                          <th class="border-set">No</th>
                          <th class="border-set">Tanggal</th>
                          <th class="border-set">Tim</th>
                          <th class="border-set">Pelaksana</th>
                          <th class="border-set">Jabatan</th>
                          <th class="border-set">Lokasi Pemeliharaan</th>
                          <th class="border-set">Pelapor</th>
                          <th class="border-set">jumlah Tc</th>
                          <th class="border-set">Pemakaian Komponen</th>
                          <th class="border-set">Volume</th>
                          <th class="border-set">Satuan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(dataAssignment, index) in dataPenugasan" :key="index">
                          <td class="border-set">{{ index + 1 }}</td>
                          <td class="border-set">{{ $moment(dataAssignment.assignment_date).format("DD MMMM YYYY") }}</td>
                          <td class="border-set">{{ dataAssignment.team !== null ? dataAssignment.team.name : "-" }}</td>
                          <td class="border-set">
                            <p v-for="(dataPetugas, indexP) in dataAssignment.assignment_petugas" :key="indexP">
                              {{ indexP + 1 }}. {{ dataPetugas.petugas.full_name }}
                            </p>
                          </td>
                          <td class="border-set">
                            <p v-for="(dataPetugas, indexP) in dataAssignment.assignment_petugas" :key="indexP">
                              {{ dataPetugas.petugas.position }}
                            </p>
                          </td>
                          <td class="border-set">
                            <p v-for="(dataComplaint, indexC) in dataAssignment.assignment_complaints" :key="indexC">
                              {{ dataComplaint.complaint.address }}
                            </p>
                          </td>
                          <td class="border-set">
                            <p v-for="(dataComplaint, indexC) in dataAssignment.assignment_complaints" :key="indexC">
                              {{ dataComplaint.complaint.reporter }}
                            </p>
                          </td>
                          <td class="border-set text-center">
                            <p v-for="(dataComplaint, indexC) in dataAssignment.assignment_complaints" :key="indexC">
                              {{ dataComplaint.total_tc !== null ? dataComplaint.total_tc : '0' }}
                            </p>
                          </td>
                          <td class="border-set">
                            <div v-for="(dataComplaint, indexC) in dataAssignment.assignment_complaints" :key="indexC">
                              <div v-if="dataComplaint.complaint && dataComplaint.complaint.item_usage">
                                <div v-for="detail, indexN in dataComplaint.complaint.item_usage.item_usage_details" :key="indexN">
                                  {{ indexN + 1 }}. {{ detail.item.name !== null ? detail.item.name : '-' }}
                                </div>
                              </div>
                              <div v-else>-</div>
                            </div>
                          </td>
                          <td class="border-set">
                            <div v-for="(dataComplaint, indexC) in dataAssignment.assignment_complaints" :key="indexC">
                              <div v-if="dataComplaint.complaint && dataComplaint.complaint.item_usage">
                                <div v-for="detail in dataComplaint.complaint.item_usage.item_usage_details" :key="detail.id">
                                  {{ detail.qty || 0 }}
                                </div>
                              </div>
                              <div v-else>-</div>
                            </div>
                          </td>
                          <td class="border-set">
                            <div v-for="(dataComplaint, indexC) in dataAssignment.assignment_complaints" :key="indexC">
                              <div v-if="dataComplaint.complaint && dataComplaint.complaint.item_usage">
                                <div v-for="detail in dataComplaint.complaint.item_usage.item_usage_details" :key="detail.id">
                                  {{ detail.item.unit || 0 }}
                                </div>
                              </div>
                              <div v-else>-</div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="border-set text-center" colspan="7">Jumlah Total</td>
                          <td class="border-set text-center">
                            {{ calculateTotalTc(dataPenugasan) }}
                          </td>
                          <td class="border-set"> </td>
                          <td class="border-set"> </td>
                          <td class="border-set"> </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <p>Data Tidak Ditemukan</p>
    </div>
    <!-- Modal -->
    <CModal 
      size="sm"
      :title="'Pilih Format Print'"
      centered
      :show.sync="showModal"
      :closeOnBackdrop="false"
      >
        <template>
          <div class="modal-body">
            <p>Silahkan pilih format print</p>
          </div>
        </template>
        <template #footer>
          <div class="modal-footer">
            <CButton @click="exportAsPDF" class="btn btn-primary">PDF</CButton>
            <CButton @click="exportAsExcel" class="btn btn-primary">Excel</CButton>
            <CButton @click="closeModal" class="btn btn-secondary mr-3">Close</CButton>
          </div>
        </template>
      </CModal>
  </section>
</template>

<script>

export default {
  data() {
    return {
      id : null,
      dataPenugasan: null,
      dataItem: null,
      params: {
        tahun: "",
      },
      showModal: false,
    };
  },
  methods: {
    getData() {
      var loading = this.$loading.show();
      this.$store.dispatch("report/getReportPerYear", this.params).then((resp) => {
        loading.hide();
        this.dataPenugasan = resp.data;
        // console.log(this.dataPenugasan)
        this.showModal = true;
        // setTimeout(function () {
        //   window.print();
        // }, 5000);
      }).catch(e => {
        this.$toast.error(e);
        loading.hide();
      });
    },
    calculateTotalTc(assignmentComplaints) {
      let sum = 0;
      for (const dataAsg of assignmentComplaints) {
        for (const dataCl of dataAsg.assignment_complaints) {
          if (dataCl.total_tc !== null) {
            sum += parseFloat(dataCl.total_tc);
          }
        }
      }

      return sum;
    },
    closeModal() {
      this.showModal = false;
      window.close();
    },
    exportAsPDF() {
      this.showModal = false;
      setTimeout(function () {
          window.print();
      }, 5000);
    },
    exportAsExcel() {
      const dataToExport = this.dataPenugasan;

      let csvContent = `KEGIATAN PEMELIHARAAN PJU TAHUN ${this.$moment(dataToExport[0].assignment_date).format("YYYY")}\n`;
      csvContent += 'No,Tanggal,Tim,Pelaksana,Jabatan,Lokasi Pelaksanaan,Pelapor,Jumlah Tc,Pemakaian Komponen,Volume,Satuan\n';

      dataToExport.forEach((dataAssignment, index) => {
        const petugasNames = dataAssignment.assignment_petugas.map((assignment_petugas, petugasIndex) => `${petugasIndex + 1}. ${assignment_petugas.petugas.full_name || "-"}`).join('\n');
        const petugasPosition = dataAssignment.assignment_petugas.map((assignment_petugas) => `${assignment_petugas.petugas.position || "-"}`).join('\n');
        const lokasi = dataAssignment.assignment_complaints.map((assignment_complaints) => `${assignment_complaints.complaint.address || "-"}`).join('\n');
        const pelapor = dataAssignment.assignment_complaints.map((assignment_complaints) => `${assignment_complaints.complaint.reporter || "-"}`).join('\n');
        const jmlTc = dataAssignment.assignment_complaints.map((assignment_complaints) => `${assignment_complaints.total_tc || "0"}`).join('\n');
        const itemUsageDetails = dataAssignment.assignment_complaints.map((assignment_complaints) => {
          if (assignment_complaints.complaint && assignment_complaints.complaint.item_usage) {
            return assignment_complaints.complaint.item_usage.item_usage_details.map((detail, indexN) => `${indexN + 1}. ${detail.item.name !== null ? detail.item.name : '-'}`).join('\n');
          } else {
            return "-";
          }
        }).join('\n');
        const itemQuantities = dataAssignment.assignment_complaints.map((assignment_complaints) => {
          if (assignment_complaints.complaint && assignment_complaints.complaint.item_usage) {
            return assignment_complaints.complaint.item_usage.item_usage_details.map((detail) => `${detail.qty || "-"}`).join('\n');
          } else {
            return "-";
          }
        }).join('\n');

        const itemUnits = dataAssignment.assignment_complaints.map((assignment_complaints) => {
          if (assignment_complaints.complaint && assignment_complaints.complaint.item_usage) {
            return assignment_complaints.complaint.item_usage.item_usage_details.map((detail) => `${detail.item.unit || "-"}`).join('\n');
          } else {
            return "-";
          }
        }).join('\n');
        csvContent += `${index + 1},`;
        csvContent += `"${this.$moment(dataAssignment.assignment_date).format("DD MMMM YYYY")}",`;
        csvContent += `"${dataAssignment.team !== null ? dataAssignment.team.name : "-"}",`;
        csvContent += `"${petugasNames}",`;
        csvContent += `"${petugasPosition}",`;
        csvContent += `"${lokasi}",`;
        csvContent += `"${pelapor}",`;
        csvContent += `"${jmlTc}",`;
        csvContent += `"${itemUsageDetails}",`;
        csvContent += `"${itemQuantities}",`;
        csvContent += `"${itemUnits}"`;
        csvContent += '\n';
      });

      const blob = new Blob([csvContent], { type: 'text/csv' });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'KegiatanPemeliharaanPJUTahunan.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.showModal = false;
    },
  },
  computed: {
    computedData() {
      return this.dataPenugasan.map((item) => {
        return {
          ...item,
        }
      });
    }
  },
  created() {
    this.params.tahun = this.$route.query.tahun;
    console.log(this.params.tahun);
  },
  mounted() {
    this.getData()
  },
};
</script>
  
  
<style scoped>
  body {
    font-size: 8px;
  }
  
  #judul {
    color: black;
    text-align: center;
    font-family: "Times New Roman", Times, serif;
    font-weight: bold;
    font-size: 28px;
    line-height: 1;
  }
  #alamat {
    color: black;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 13px;
    line-height: 1;
  }
  #kop {
    color: black;
    width: auto;
    height: auto;
    padding-top: 28px;
    padding-left: 38px;
    padding-right: 19px;
    /* padding-bottom: 28px; */
    border-bottom: 11px double black;
  }
  #halaman {
    color: black;
    width: auto;
    height: auto;
    padding-top: 28px;
    padding-left: 113px;
    padding-right: 76px;
    padding-bottom: 28px;
    font-family: "Times New Roman", Times, serif;
  }
  img {
    color: black;
    height: 110px;
  }
  #content {
    color: black;
    text-align: justify;
    font-size: 20px;
  }
  .border-set {
    /* font-size: 20px; */
    border: 2px solid #000 !important;
  }
  

</style>

<style>
@media print {
  @page {
    size: landscape;
  }
}
#pdf-content {
  /* width: calc(29.7cm - 2cm * 2); */
  /* margin: 28pt 32pt 28pt 32pt;
        word-spacing: 1pt;
        word-break: page-break; */
  color: #000 !important;
}
#content-table {
  font-size: 8pt;
  /* border-top: 1pt solid black;
  border-left: 1pt solid black; */
  border-collapse: collapse;
}
#content-table th,
#content-table td {
  /* border-right: 1px solid black;
  border-bottom: 1px solid black; */
  border: 1px solid black;
  padding-left: 3pt;
  padding-right: 3pt;
}
#footer {
  font-size: 8pt;
}
.table-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; /* Adjust as needed */
}

table {
  border-collapse: collapse;
  width: 48%; /* Adjust as needed */
  border: 1px solid #000; /* Add borders for each table */
  white-space: normal !important;
  font-size: 10px;
}
td {
  vertical-align: top;
}
p {
    color: black;
    margin: 5px;
    font-size: 12px;
  }
</style>